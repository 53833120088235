import React from "react";
import Image from "../Hacks/Image";
import { Link } from "gatsby";
import { Fade } from "react-awesome-reveal";

export default class JournalCard extends React.Component {
  render() {
    const { featured_media, date, title, excerpt, slug, readtime } = this.props;

    return (
      <div className="card-journal card h-100 p-4">
        <Fade>
          <React.Fragment>
            {featured_media && (
              <Image
                image={featured_media}
                className="w-100 bg-light floaty card-journal__image"
                alt={title}
              />
            )}
            {!featured_media && (
              <div className="card-journal__image bg-primary-grad" />
            )}

            <div className="card-body-inner">
              <div className="row mt-4 mb-1">
                <div className={`col-8 h6`}>{date}</div>
                {readtime && (
                  <div className="col-4 text-right small font-weight-bold text-primary">
                    {readtime} min read
                  </div>
                )}
              </div>
              <Link
                cover={`true`}
                direction="right"
                bg="#1F1A3C"
                className="text-primary small stretched-link"
                to={slug}
              >
                <h2 className="h5 card-title text-secondary"><span>{title}</span></h2>
              </Link>
              <p className="card-text mb-4">{excerpt}</p>
            </div>
          </React.Fragment>
        </Fade>
      </div>
    );
  }
}
