import React from "react";
import Image from "../Hacks/Image";
import { Link } from "gatsby";

import { Fade } from "react-awesome-reveal";

export default class JournalCardLarge extends React.Component {
  render() {
    const { slug, featured_media, readtime, date, title, excerpt } = this.props;

    return (
      <div className="card card-journal card-journal-large mb-4 shadow">
        <Fade>
          <div className="card-body p-4 row pr-md-5">
            <div className="card-img col-lg-7 align-self-center">
              <Link
                cover={`true`}
                direction="right"
                bg="#1F1A3C"
                className="text-primary small stretched-link"
                to={slug}
              >
                {featured_media && (
                  <Image image={featured_media} className="w-100 h-100" />
                )}
              </Link>
            </div>
            <div className="col-lg-5 col-xl-5">
              <div className="row mt-4 mb-1">
                <div className={`col-8 h6`}>{date}</div>
                {readtime && (
                  <div className="col-4 text-right small font-weight-bold text-primary">
                    {readtime} min read
                  </div>
                )}
              </div>
              <Link
                cover={`true`}
                direction="right"
                bg="#1F1A3C"
                className="text-primary small stretched-link"
                to={slug}
              >
                <h2 className="h2 card-title text-secondary">{title}</h2>
              </Link>
              <div
                className="lead-lg card-text"
                dangerouslySetInnerHTML={{
                  __html: excerpt,
                }}
              />
            </div>
          </div>
        </Fade>
      </div>
    );
  }
}
