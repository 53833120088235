import React, { useState } from "react";
import { Link } from "gatsby";

const Pagination = ({ pageContext, pathPrefix }) => {
  const { previousPagePath, nextPagePath, numberOfPages, humanPageNumber } =
    pageContext;

  const [open, setOpen] = useState(false);

  return (
    <div
      className="bg-light pb-5 pagination justify-content-center"
      role="navigation"
      aria-label="Page navigation"
    >
      <ul className="pagination">
        {previousPagePath && (
          <>
            <li className="page-item">
              <Link
                to={previousPagePath}
                rel="prev"
                className="mx-2 btn btn-sm btn-outline-primary"
              >
                Prev
              </Link>
            </li>
          </>
        )}

        <li className="pagination d-inline-block d-lg-none">
          <button
            className="btn btn-sm btn-secondary dropdown-toggle"
            type="button"
            onClick={() => setOpen(!open)}
            onKeyDown={() => setOpen(!open)}
          >
            PAGE {humanPageNumber}
          </button>
          <div
            className={`d-lg-none pagination-container ${
              open ? "d-inline-block" : "d-none"
            }`}
          >
            {Array.from({ length: numberOfPages }, (_, i) => (
              <li className="pagination-item" key={`pagination-number${i + 1}`}>
                <Link
                  className={`btn btn-sm ${
                    humanPageNumber === i + 1
                      ? "btn-secondary"
                      : "btn-outline-primary"
                  } d-block`}
                  to={i === 0 ? `/blog/` : `/blog/page/${i + 1}`}
                >
                  {i + 1}
                </Link>
              </li>
            ))}
          </div>
        </li>

        {Array.from({ length: numberOfPages }, (_, i) => (
          <li className="page-item" key={`pagination-number${i + 1}`}>
            <Link
              className={`mx-1 d-none d-lg-block btn btn-sm px-3 ${
                humanPageNumber === i + 1
                  ? `btn-secondary`
                  : `btn-outline-primary`
              }`}
              to={i === 0 ? `/blog/` : `/blog/page/${i + 1}`}
            >
              {i + 1}
            </Link>
          </li>
        ))}

        {nextPagePath && (
          <>
            <li className="page-item">
              <Link
                to={nextPagePath}
                rel="next"
                className="mx-2 btn btn-sm btn-outline-primary"
              >
                Next
              </Link>
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

export default Pagination;
